<template>
  <b-modal
      :id="'show-url2'+slug"
      centered
      no-close-on-backdrop
  >
    <template #modal-header>
      <h3>{{ $t('modals.showItemUrl.title') }}</h3>
    </template>
    <div class="show-url-icon text-center px-2">
      <b-icon icon="check-circle" variant="success" class="mt-5 mb-4"></b-icon>
      <p class="mt-2 mb-2" v-html="$t('modals.showItemUrl.message')"></p>
      <div class="mb-2 px-2">
        <p class="mb-4"><a class="show-url" :href="publishingUrl"
          target="_blank"><u>{{ publishingUrl }}</u></a></p>
          <b-button v-b-toggle.collapse-2 class="m-1 light-grey-button">
            {{ $t('modals.qrCode.button') }}</b-button>
            <!-- Element to collapse -->
            <b-collapse id="collapse-2">
              <div class="pt-3">
                    <p><img :src="`https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=${publishingUrl}`" alt=""></p>
                    <p><a :href="`https://api.qrserver.com/v1/create-qr-code/?format=svg&data=${publishingUrl}&download=1`">{{ $t('modals.qrCode.svg') }}</a>
                    <br>
                    <a :href="`https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&format=jpg&data=${publishingUrl}&download=1`">{{ $t('modals.qrCode.jpg') }}</a></p>
              </div>
            </b-collapse>
      </div>
      <div class="mb-4 px-2" v-if="personalisedURLs.length > 0">
        <b-button v-b-toggle.collapse-3 class="mt-4 mb-2 light-grey-button">
          <span class="when-open">{{ $t('modals.personalisedURLs.closeButton') }}</span>
          <span class="when-closed">{{ $t('modals.personalisedURLs.openButton') }}</span>
        </b-button>
          <!-- Element to collapse -->
          <b-collapse id="collapse-3">
            <div v-for="item in personalisedURLs" :key="item">
                <a :href="`${publishingUrl}?pz=${item}`" target="_blank">
                  {{ `${publishingUrl}?pz=${item}` }}
                </a>
            </div>
          </b-collapse>
      </div>

    </div>
    <template #modal-footer>
      <b-button
        class="p-3"
        block
        @click="$emit('close-modal')"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GetPersonalizedVariableSetsItems from '@/graphQlQueries/queries/getPersonalizedVariableSetsItems';

export default {
  name: 'ModalShowUrl',
  props: ['slug', 'slugPath', 'pageType', 'groupDomain', 'itemPath'],
  data: () => ({
    publishingUrl: null,
    personalisedURLs: [],
    loading: 0,
  }),
  created() {
    this.publishingUrl = `${this.groupDomain}${this.slugPath}`;
    // if project add / at the end
    if (this.pageType === 'project') {
      this.publishingUrl = `${this.publishingUrl}/`;
    }
    console.log('show url', this.itemPath);
  },
  mounted() {
    this.$bvModal.show(`show-url2${this.slug}`);
  },
  apollo: {
    loading: {
      query: GetPersonalizedVariableSetsItems,
      variables() {
        return {
          itemPath: this.itemPath,
          addValues: true,
        };
      },
      update(data) {
        this.personalisedURLs = [];
        if (data.getPersonalizedVariableSetsByPath.length) {
          data.getPersonalizedVariableSetsByPath.forEach((set) => {
            if (set.values && set.values.length) {
              set.values.forEach((value) => {
                if (!this.personalisedURLs.includes(value.client_key)) {
                  this.personalisedURLs.push(value.client_key);
                }
              });
            }
          });
        }
      },
      skip() {
        return !this.itemPath;
      },
      fetchPolicy: 'network-only',
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 60px;
}
.show-url {
  font-size: 1.1rem;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
