var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "show-url2" + _vm.slug,
        centered: "",
        "no-close-on-backdrop": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [_vm._v(_vm._s(_vm.$t("modals.showItemUrl.title")))])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-modal")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "show-url-icon text-center px-2" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "check-circle", variant: "success" }
          }),
          _c("p", {
            staticClass: "mt-2 mb-2",
            domProps: {
              innerHTML: _vm._s(_vm.$t("modals.showItemUrl.message"))
            }
          }),
          _c(
            "div",
            { staticClass: "mb-2 px-2" },
            [
              _c("p", { staticClass: "mb-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "show-url",
                    attrs: { href: _vm.publishingUrl, target: "_blank" }
                  },
                  [_c("u", [_vm._v(_vm._s(_vm.publishingUrl))])]
                )
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-2",
                      modifiers: { "collapse-2": true }
                    }
                  ],
                  staticClass: "m-1 light-grey-button"
                },
                [_vm._v(" " + _vm._s(_vm.$t("modals.qrCode.button")))]
              ),
              _c("b-collapse", { attrs: { id: "collapse-2" } }, [
                _c("div", { staticClass: "pt-3" }, [
                  _c("p", [
                    _c("img", {
                      attrs: {
                        src: `https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=${_vm.publishingUrl}`,
                        alt: ""
                      }
                    })
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `https://api.qrserver.com/v1/create-qr-code/?format=svg&data=${_vm.publishingUrl}&download=1`
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("modals.qrCode.svg")))]
                    ),
                    _c("br"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&format=jpg&data=${_vm.publishingUrl}&download=1`
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("modals.qrCode.jpg")))]
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm.personalisedURLs.length > 0
            ? _c(
                "div",
                { staticClass: "mb-4 px-2" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-3",
                          modifiers: { "collapse-3": true }
                        }
                      ],
                      staticClass: "mt-4 mb-2 light-grey-button"
                    },
                    [
                      _c("span", { staticClass: "when-open" }, [
                        _vm._v(
                          _vm._s(_vm.$t("modals.personalisedURLs.closeButton"))
                        )
                      ]),
                      _c("span", { staticClass: "when-closed" }, [
                        _vm._v(
                          _vm._s(_vm.$t("modals.personalisedURLs.openButton"))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-collapse",
                    { attrs: { id: "collapse-3" } },
                    _vm._l(_vm.personalisedURLs, function(item) {
                      return _c("div", { key: item }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `${_vm.publishingUrl}?pz=${item}`,
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(`${_vm.publishingUrl}?pz=${item}`) +
                                " "
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }